import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const QUESTIONS = [
  {
    prompt: "You are broke AF and need to get some cash ASAP",
    img: "moneyprintergobrrr.jpeg",
    options: [
      {text: "Money printer go brrrrr haha", value: 5},
      {text: "Tax the American colonies", value: 20},
      {text: "Look at yourself in the mirror and admire your beauty", value: 0},
    ]
  },
  {
    prompt: "The colonies are mad at you and start protesting and boycotting British goods",
    img: "notstonky.jpeg",
    options: [
      {text: 'Remove the taxes', value: 20},
      {text: "Send an army and execute anyone who doesn’t like you", value: 5},
      {text: 'Look at yourself in the mirror and admire your beauty.', value: 0},
    ]
  },
  {
    prompt: "You’re still broke AF, and still need to get some cash ASAP",
    img: "emptywallet.jpeg",
    options: [
      {text: "Look at yourself in the mirror and admire your beauty", value: 0},
      {text: "Money printer go brrrrr haha", value: 5},
      {text: "Tax the american colonies yet again", value: 20},
    ]
  },
  {
    prompt: "The colonies are mad at you and start protesting and boycotting British goods",
    img: "notstonky.jpeg",
    options: [
      {text: "Remove the taxes", value: 20},
      {text: "Send an army and execute anyone who doesn’t like you", value: 5},
      {text: "Look at yourself in the mirror and admire your beauty", value: 0},
    ]
  },
  {
    prompt: "You’re still broke, and still need to get some cash ASAP",
    img: "shrug.jpeg",
    options: [
      {text: "Tax the american colonies and send some troops there to let them know who’s boss", value: 10},
      {text: "Look at yourself in the mirror and admire your beauty", value: 0},
      {text: "Money printer go brrrrr haha", value: 5},
    ]
  },
  {
    prompt: "The Americans dumped your tea into the ocean!",
    img: "teaparty.jpeg",
    options: [
      {text: "It’s okay, I don’t actually like tea", value: 5},
      {text: "Send a ton more troops and take direct control of Massachusetts", value: 10},
      {text: "Look at yourself in the mirror and admire your beauty", value: 0},
    ]
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const RomanEmperorSimulator = () => {
  const [questionIndex, setQuestionIndex]= useState(0)
  const [prob, setProb] = useState(0)
  const [diff, setDiff] = useState(0)
  const chances = getRandomInt(100)
  console.log(chances)
  const wouldBeKilled = prob > chances

  const onSelectOption = (option) => {
    setDiff(option.value)
    setProb(prob + option.value)
    setQuestionIndex(questionIndex+1)
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      padding-bottom: 50px;
    `}>
      <h1 css={css`
        margin-bottom: 10px;
        text-align: center;
      `}>British Simulator</h1>
      <img css={css`
        height: 100px;

      `} src="/britflag.png" alt="British Flag"></img>

      <h3>Your goal is to cause the American Revolution</h3>
      <label for="file">Revolution Probability ({prob}%)</label>
<progress id="file" value={prob} max="100"> {prob}% </progress>
<div css={css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`}>

  {questionIndex < QUESTIONS.length ? (
    <>
      <h2 css={css`
        text-align: center;
      `}>{QUESTIONS[questionIndex].prompt}</h2>

  <img css={css`
    max-height: 100px;
    margin-top: 15px;
  `} src={QUESTIONS[questionIndex].img} alt="something funny"></img>
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  `}>
  {QUESTIONS[questionIndex].options.map((option) => (
    <button css={css`
    margin-top: 20px;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
    width: 350px;
    word-wrap: break-word;
    `}
    onClick={() => onSelectOption(option)}
    >
     { option.text}
      </button>
  ))}
  </div>
  </>
  ) : wouldBeKilled ?  (
    <div css={css`text-align: center;`}>
      <h2>The Americans Have Declared Independence!</h2>
      <img css={css`
        max-width: 400px;
      `} src="/hami.jpeg"/>
      <h3>You'll be back, soon you'll see. You'll remember you belong to me...</h3>
    </div>
  ) : <div css={css`text-align: center;`}>
      <h2>Mission Failed: The Americans remained loyal to you.</h2>
      <img css={css`
       max-width: 400px;
      `} src="/kghamilton.jpeg"/>
      <h3>Good job I guess.</h3>
    </div>}

</div>
    </div>
  )
}
  export default RomanEmperorSimulator;